import { ResponseGenerator } from '../models'
import { Reserve } from '../models/Reserve'
import baseAxios from './clientAPI'

interface ReserveResponse extends ResponseGenerator {
  data: {
    entry: Reserve[]
    messageError?: string
  }
}

const mobileAppMissionApi = {
  getReserve(attenduId: string): Promise<ReserveResponse> {
    const url = `/warehouse/reserve/reserve-on-attendu?attendu_id=${attenduId}`
    return baseAxios.get(url)
  },
}

export default mobileAppMissionApi
