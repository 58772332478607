import { cloneDeep } from 'lodash'
import { Reserve } from '../../../models/Reserve'
import { DataTableParam } from '../stockscreen/component/model'

export const mergeReservesToAttenduData = (
  data: DataTableParam[][],
  reserveData: Reserve[]
): DataTableParam[][] => {
  let result: DataTableParam[][] = cloneDeep(data)

  for (let i = 0; i < result.length; i++) {
    const currentLine = result[i]
    const ssccField = currentLine.find((item) => item.name === 'sscc')
    if (!ssccField) continue

    const ssccCorresponds =
      reserveData.filter((item) => item.sscc === ssccField.value) || []
    if (ssccCorresponds.length === 0) continue

    const reservesIndex = currentLine.findIndex(
      (item) => item.name === 'reserves'
    )
    result[i][reservesIndex].dataInTooltip = ssccCorresponds
  }

  return result
}
