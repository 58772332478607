import { useState } from 'react'
import { TagsInput } from 'react-tag-input-component'

function validateEmails(vals: string[]) {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  let result = true
  vals.forEach((email) => {
    if (!email) {
      return
    }
    const isValidEmail = emailPattern.test(email)
    if (!isValidEmail) {
      result = false
      return
    }
  })
  return result
}

// eslint-disable-next-line import/no-anonymous-default-export
export default ({
  changeValue,
  values,
}: {
  changeValue: (data: string[]) => void
  values: string[]
}) => {
  const [isError, setError] = useState(false)
  const handleChange = (data: Array<string>) => {
    changeValue(data)
    setError(!validateEmails(data))
  }

  return (
    <div>
      <div
        className={isError ? 'border-2 border-solid border-red rounded-md' : ''}
      >
        <TagsInput
          value={values}
          onChange={handleChange}
          separators={['Enter', ' ']}
          classNames={{ input: '!w-32' }}
          onBlur={(e: any) => {
            setError(!validateEmails([...values, e.target.value]))
          }}
        />
      </div>
      {isError && <p className="text-red">Entrée Invalide</p>}
    </div>
  )
}
