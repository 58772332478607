import { Col, Row } from 'antd'
import { Header } from '../stockscreen/component/ITable'
import { CInput, CTable } from '../stockscreen/component/Wrapper'
import { TitleParam } from '../stockscreen/component/model'
import { Line, mapDataWithHeader } from './Common'
import { FData } from './model'

const header1: TitleParam[] = [
  {
    label: 'Adresse',
    name: 'address',
    cInputProps: {},
  },
  {
    label: 'Complément',
    name: 'complement',
    cInputProps: {},
  },
  {
    label: 'GLN',
    name: 'gln',
    cInputProps: {},
  },
]

const header2: TitleParam[] = [
  {
    label: 'Contact',
    name: 'contact',
    cInputProps: {},
  },
  {
    label: 'Tel',
    name: 'tel',
    cInputProps: {},
  },
  {
    label: 'Mobile',
    name: 'mobile',
    cInputProps: {},
  },
  {
    label: 'Email',
    name: 'email',
    cInputProps: {},
  },
]

export default function FournisseurView(props: FData) {
  return (
    <div>
      <Row className="flex items-center mb-4">
        <Col span={4}>
          <span className="form-label">Code & Nom Fournisseur</span>
        </Col>
        <Col span={6}>
          <span>
            <CInput className="custom-input" disabled value={props?.code_nom} />
          </span>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col span={10}>
          <CTable>
            <Header header={header1} hideCols={[]} />
            {mapDataWithHeader(header1, props.data1).map((item, idx) => {
              return <Line key={idx} data={item} />
            })}
          </CTable>
        </Col>
        <Col span={14}>
          <CTable>
            <Header header={header2} hideCols={[]} />
            {mapDataWithHeader(header2, props.data2).map((item, idx) => {
              return <Line key={idx} data={item} />
            })}
          </CTable>
        </Col>
      </Row>
    </div>
  )
}
