import baseAxios from './clientAPI'

export interface SendMailResponse {
  code?: number
  data?: any
  error?: any
  status?: string
}

interface EmailRequest {
  sender: string
  to?: string[]
  cc?: string[]
  bcc?: string[]
  subject?: string
  body?: string
  type: string
}

const suiviApi = {
  sendMail(data: EmailRequest): Promise<SendMailResponse> {
    const url = `/email`
    return baseAxios.post(url, data)
  },
}

export default suiviApi
