import { ArrowsAltOutlined } from '@ant-design/icons'
import { Modal, Tooltip } from 'antd'
import { SyntheticEvent, useState } from 'react'
import NOT_FOUND_IMAGE from '../../../assets/images/image-not-found.png'
import { PhotoViewType } from '../../../enum/photoType'
import { FileResponse } from '../../../models'
import './Attendu.scss'

interface PhotoViewInterface {
  /**
   * data return from api (include photos taken bby app)
   */
  data?: FileResponse[]
  /**
   * function choose photos if photo view type is SUIVI
   * @param choosenPhotos
   * @returns void
   */
  onChangeData?: (choosenPhotos: FileResponse[]) => void
  /**
   * photo view type
   */
  type?: PhotoViewType
  /**
   * function pass photo just clicked if photo view type is SUIVI
   * @param choosenPhoto
   * @returns void
   */
  onAddPhoto?: (file: FileResponse) => void
  /**
   * photos choosen when onclick in suivi view
   */
  choosenPhotos?: FileResponse[]
  showImageName?: boolean
}

interface PreviewPhoto {
  /**
   * status preview if onclick preview photo
   */
  isPreview?: boolean
  /**
   * path of photo
   */
  photo?: string
}

/**
 * the @PhotoView is a component display photos of Attendu Send on mission
 * @author [anhnq]
 * @param props
 * @returns
 */
export default function PhotoView(props: PhotoViewInterface) {
  const {
    data = [],
    onChangeData = () => {},
    type,
    onAddPhoto,
    choosenPhotos = [],
    showImageName,
  } = props
  const [previewStatus, setPreviewStatus] = useState<PreviewPhoto>({
    isPreview: false,
    photo: '',
  })
  const [isChoosen, setIsChoosen] = useState<boolean>(false)
  const [errorPhoto, setErrorPhoto] = useState<string[]>([])

  const baseURL = process.env.REACT_APP_API_ENDPOINT

  //when choose photo of SUIVI
  const onClickPhoto = (file: FileResponse) => {
    if (type === PhotoViewType.MISSON) return
    setIsChoosen(!isChoosen)
    if (!choosenPhotos.map((el) => el.path).includes(file.path)) {
      onChangeData([...choosenPhotos, file])
      onAddPhoto?.(file)
    } else {
      const filterPhotos = choosenPhotos.filter(
        (item) => item.path !== file.path
      )
      onChangeData(filterPhotos)
    }
  }

  const onErrorImage = (
    e: SyntheticEvent<HTMLImageElement, Event>,
    path: string
  ) => {
    e.currentTarget.src = NOT_FOUND_IMAGE
    setErrorPhoto([...errorPhoto, ...[path]])
  }

  return (
    <>
      <Modal
        open={previewStatus.isPreview}
        footer={null}
        onCancel={() => setPreviewStatus({ isPreview: false })}
        closable={false}
        centered
        width={'60vw'}
        className="bg-center bg-contain bg-no-repeat modal-preview"
        style={{
          height: '90vh',
          backgroundImage: `${
            previewStatus.photo && errorPhoto.includes(previewStatus.photo)
              ? `url(${NOT_FOUND_IMAGE})`
              : `url(${baseURL}/files/attendu/show/${previewStatus.photo})`
          }`,
        }}
        data-testid="modal-preview"
      ></Modal>
      <div className="flex flex-wrap">
        {data.map((item, index) => {
          return (
            <div
              key={index}
              className={`relative mr-5 mb-5 ${
                choosenPhotos.includes(item)
                  ? 'border-4 border-solid border-secondary rounded-md'
                  : ''
              }`}
            >
              <>
                <ArrowsAltOutlined
                  className="absolute z-10 top-0 right-0 bg-white cursor-pointer"
                  onClick={() =>
                    setPreviewStatus({
                      isPreview: true,
                      photo: item.path,
                    })
                  }
                  data-testid="img-photo-preview"
                />
              </>

              <img
                className=" bg-cover bg-center aspect-video object-cover"
                height={100}
                onClick={() => {
                  onClickPhoto(item)
                }}
                src={`${baseURL}/files/attendu/show/${item.path}`}
                alt={item.path}
                onError={(e) => {
                  onErrorImage(e, item.path)
                }}
                data-testid="img-photo-view"
              />
              {showImageName && (
                <Tooltip placement="bottom" title={item.image_tooltip}>
                  <div className="text-center">{item.image_name}</div>
                </Tooltip>
              )}
            </div>
          )
        })}
      </div>
      {type === PhotoViewType.MISSON && (
        <div
          className="flex justify-end mr-20 italic"
          data-testid="photo-length"
        >
          {data.length === 0
            ? ''
            : data.length === 1
            ? `${data.length} photo`
            : `${data.length} photos`}
        </div>
      )}
    </>
  )
}
