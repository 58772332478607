import React from 'react'

interface CommentaireData {
  data?: string
}

export default function Commentaire(props: CommentaireData) {
  return (
    <textarea
      className="custom-input-txt-area"
      cols={50}
      rows={10}
      value={props?.data}
      disabled
    ></textarea>
  )
}
