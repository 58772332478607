import { MissionRole } from './AttenduOnMission'

interface MissionProps {
  data?: MissionRole
}

const Mission = (props: MissionProps) => {
  return (
    <table className="w-full border-collapse">
      <tr>
        <th
          className=" py-3 px-8 form-label bg-[#efefef]"
          style={{
            borderBottom: '1px solid #b7b7b7',
            borderRight: '1px solid #b7b7b7',
            borderTopLeftRadius: 10,
            width: '20%',
          }}
        >
          Responsable
        </th>
        <td
          className="text-left py-3 px-8  form-label"
          style={{ borderBottom: '1px solid #b7b7b7' }}
        >
          {props.data?.responsable || '-'}
        </td>
      </tr>
      <tr>
        <th
          className=" py-3 px-8 form-label bg-[#efefef]"
          style={{
            borderRight: '1px solid #b7b7b7',
            borderBottomLeftRadius: 10,
          }}
        >
          Stockiste
        </th>
        <td className="text-left py-3 px-8 form-label">
          {props.data?.stockiste || '-'}
        </td>
      </tr>
    </table>
  )
}

export default Mission
