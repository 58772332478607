import { Button, Col, Input, notification, Row, Modal, Alert } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { CInput } from '../stockscreen/component/Wrapper'
import { MailOutlined } from '@ant-design/icons'
import PhotoView from './PhotoView'
import { PhotoViewType } from '../../../enum/photoType'
import { Attendu, FileResponse } from '../../../models'
import suiviApi, { SendMailResponse } from '../../../http/suiviApi'
import Error from '../../../assets/images/error.png'
import CloseIcon from '../../../assets/images/iconClose.png'
import Document from '@tiptap/extension-document'
import Dropcursor from '@tiptap/extension-dropcursor'
import Image from '@tiptap/extension-image'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { useEditor } from '@tiptap/react'
import { CustomTextArea } from '../../../components/Common/CustomTextarea'
import InputEmails from '../../../components/Common/InputEmails'
import Define from '../../../constants/define'
import { StatusAttendu } from '../../../enum/StatusAttendu'
import clsx from 'clsx'

const baseURL = process.env.REACT_APP_API_ENDPOINT

const SuiviView = ({
  resultAttendu,
  errorImages,
  objectEmail,
  blNo,
}: {
  resultAttendu?: Attendu
  errorImages: any[]
  objectEmail?: string
  blNo: string
}) => {
  const [showForm, setShowForm] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [chosenImages, setChosenImage] = useState<FileResponse[]>([])
  const [ccEmails, setEmails] = useState<string[]>([])
  const [showEmptyWarning, setEmptyWarning] = useState(false)
  const [showEmptyImageWarning, setEmptyImageWarning] = useState(false)
  const isValide =
    resultAttendu?.status === StatusAttendu.COMPLETE &&
    resultAttendu.att_production.every(
      (el) => el.status === StatusAttendu.COMPLETE
    )

  const listImage = useMemo(() => {
    return errorImages.map((item) => {
      const { name } = item
      const sscc = name.split('_')[0]
      const trimName = name.split('.')[0]
      const tooltip =
        trimName?.split('_')?.[0] +
        ' - ' +
        trimName?.split('_')?.[1] +
        ' - ' +
        trimName?.split('_')?.[2]
      return {
        ...item,
        image_name: sscc,
        image_tooltip: tooltip,
      }
    })
  }, [errorImages])

  const defaultTemplate = useMemo(() => {
    let images = ''
    let ssccMotifList: string[] = []
    listImage.forEach((image) => {
      const ssccMotif =
        image?.name?.split('_')?.[0] + ' - ' + image?.name?.split('_')?.[1]
      if (!ssccMotifList.includes(ssccMotif)) {
        ssccMotifList.push(ssccMotif)
        images = `${images}<p>${image.image_tooltip}</p>`
      }
    })
    return `<p>Bonjour Madame/Monsieur ${
      resultAttendu?.client_code_nom?.split('_')[1] || ''
    },</p>
    <p>Nous tenons à vous informer de certains problèmes liés au BL ${blNo}.</p>
    <p>Veuillez vérifier les Photos ci-dessous:</p>
    ${images}
    <p>Bien cordialement,</p>
    <p>${resultAttendu?.warehouse_code_nom}</p>
    <img src="https://api.equateur.pprod.bfast-vn.net/api/v1/files/attendu/show/at_file_84e8c7d1-67ef-4a67-af47-4f21bcd8235e-atd_d239fe01-fe36-46f7-921b-17d8f9bd575e.png" style="width: 400px; height: auto" />
    <p></p>
  `
  }, [blNo, resultAttendu, listImage])

  const openForm = () => {
    setShowForm(true)
    setTimeout(() => {
      const elements = [...document.getElementsByClassName('rti--input')]
      if (elements.length > 0) {
        // @ts-ignore
        elements[0].focus()
      }
    }, 1000)
  }

  const userEmail = localStorage.getItem(Define.MAIL)
  const closeForm = () => {
    editor?.commands.setContent(defaultTemplate)
    setEmails([])
    setShowForm(false)
    setChosenImage([])
  }

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Image.configure({
        HTMLAttributes: {
          style: 'max-width: 300px; height: auto; margin-right: 12px;',
        },
      }),
      Dropcursor,
    ],
    content: defaultTemplate,
  })

  useEffect(() => {
    editor?.commands.setContent(defaultTemplate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTemplate])

  function submitMail() {
    if (!editor?.getText()) {
      setEmptyWarning(true)
      return
    }
    if (!errorImages.some((item) => editor?.getHTML().includes(item.path))) {
      setEmptyImageWarning(true)
      return
    }
    const data = {
      sender: userEmail || '',
      to: [objectEmail || ''],
      cc: ccEmails,
      bcc: userEmail ? [userEmail] : [],
      subject: `Litige BL No ${blNo}`,
      body: `<div>${editor?.getHTML()}</div>`,
      type: 'attendu_error',
    }
    setLoading(true)
    suiviApi
      .sendMail(data)
      .then((res: SendMailResponse) => {
        if (res.status === 'success') {
          setShowForm(false)
        }
        editor?.commands.setContent(defaultTemplate)
        setEmails([])
        setLoading(false)
        setChosenImage([])
      })
      .catch(() => {
        notification.open({
          className: 'noti noti-error',
          message: (
            <div className="flex items-center">
              <img
                src={Error}
                alt="error"
                width={50}
                style={{ marginRight: 10 }}
              />
              Une erreur s’est produite. Merci de réessayer ultérieurement
            </div>
          ),
          placement: 'topRight',
          closeIcon: <img src={CloseIcon} alt="close" />,
          duration: 3,
        })
        setLoading(false)
      })
  }

  function chooseImage(file: FileResponse) {
    editor
      ?.chain()
      .focus()
      .setImage({ src: `${baseURL}/files/attendu/show/${file.path}` })
      .run()
  }

  if (showForm) {
    return (
      <div className="flex flex-col gap-3">
        <div className="flex gap-4 items-center">
          <MailOutlined style={{ color: '#20458f', fontSize: 40 }} />
          <p className="text-blue text-xl font-semibold">Envoi Litige</p>
        </div>
        <div className="flex items-center">
          <p className="w-28">A</p>
          <Input disabled className="w-80 h-8" placeholder={objectEmail} />
        </div>
        <div className="flex">
          <p className="w-28">CC</p>
          <InputEmails changeValue={setEmails} values={ccEmails} />
        </div>
        <div className="flex items-center">
          <p className="w-28">Objet</p>
          <Input
            disabled
            className="w-80 h-8"
            placeholder={`Litige BL No ${blNo}`}
          />
        </div>
        <div className="flex">
          <p className="w-28">Images</p>
          <div>
            <PhotoView
              onChangeData={setChosenImage}
              onAddPhoto={chooseImage}
              data={listImage}
              type={PhotoViewType.SUIVI}
              choosenPhotos={chosenImages}
              showImageName
            />
            <Alert
              message="Veuillez cliquer sur les photos pour les mettre dans le courrier"
              type="warning"
            />
          </div>
        </div>
        <div className="flex">
          <div className="w-28" />
          <CustomTextArea editor={editor} />
        </div>
        <div className="flex justify-end">
          <button
            onClick={closeForm}
            className="border-red border-solid bg-white text-red hover:text-red text-lg font-semibold px-5 rounded-lg cursor-pointer mr-4"
          >
            Annuler
          </button>
          <button
            disabled={isLoading}
            onClick={submitMail}
            data-testid="submit-button"
            className={clsx(
              'border-none bg-[#01e37a] text-white text-lg font-semibold px-4 flex items-center rounded-lg hover:text-white mr-4',
              isLoading ? 'cursor-no-drop' : 'cursor-pointer'
            )}
          >
            Envoyer
          </button>
        </div>
        <Modal
          title="Alerte"
          open={showEmptyWarning}
          footer={null}
          closable={false}
          afterClose={() => editor?.commands.setContent(defaultTemplate)}
        >
          <p />
          <p>Veuillez renseigner le contenu du courrier</p>
          <div className="w-full flex justify-center">
            <Button
              type="primary"
              onClick={() => {
                setChosenImage([])
                setEmptyWarning(false)
              }}
            >
              D'accord
            </Button>
          </div>
        </Modal>
        <Modal
          title="Alerte"
          open={showEmptyImageWarning}
          footer={null}
          closable={false}
        >
          <p />
          <p>
            Veuillez cliquer sur les photos pour les mettre dans le courrier
          </p>
          <div className="w-full flex justify-center">
            <Button
              type="primary"
              onClick={() => {
                setChosenImage([])
                setEmptyImageWarning(false)
              }}
            >
              D'accord
            </Button>
          </div>
        </Modal>
      </div>
    )
  } else {
    return (
      <div>
        <Row>
          <Col span={21}>
            <Row justify="space-between">
              <Col span={9}>
                <Row align="middle" gutter={12}>
                  <Col span={8}>
                    <span className="form-label">Mouvement</span>
                  </Col>
                  <Col span={16}>
                    <CInput
                      value="Arrivage avec attendu"
                      className="custom-input"
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={9}>
                <Row align="middle" gutter={12}>
                  <Col span={4}>
                    <span className="form-label">Type</span>
                  </Col>
                  <Col span={16}>
                    <CInput
                      value="Attendu de réception manuel"
                      className="custom-input"
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={6}>
                <Row align="middle" gutter={12}>
                  <Col>
                    <span className="form-label">Etat</span>
                  </Col>
                  <Col>
                    <CInput
                      value={isValide ? 'Validé' : 'En cours'}
                      className="custom-input"
                      disabled
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={3}>
            <Button
              disabled={errorImages.length === 0}
              onClick={openForm}
              className={errorImages.length === 0 ? '' : 'bd-2-red txt-cl-red'}
              style={{ fontSize: 13 }}
            >
              Signaler litige
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}

export default SuiviView
