import { Popconfirm } from 'antd'
import { cloneDeep } from 'lodash'
import { useState } from 'react'
import fileApi from '../../../http/fileApi'
import { FileResponse } from '../../../models'
import { convertPhotoType } from '../../../utils'
import Loading from '../Loading/Loading'
import Documents from './Documents'

interface DisplayDocumentsProps {
  openImport?: boolean
  attendu_id?: string
  onChangeData?: (newValue: FileResponse[]) => void
  closeImport?: () => void
  initData?: FileResponse[]
}

const DisplayDocuments = (props: DisplayDocumentsProps) => {
  const {
    attendu_id = '',
    onChangeData = () => {},
    openImport = false,
    closeImport = () => {},
    initData = [],
  } = props
  const [loadingUploadFile, setLoadingUploadFile] = useState<boolean>(false)
  const baseURL = process.env.REACT_APP_API_ENDPOINT

  const onConfirmImport = (data: any) => {
    closeImport()
    setLoadingUploadFile(true)

    let formData = new FormData()
    for (let i = 0; i < data.length; i++) {
      formData.append('files', data[i])
    }
    formData.append('att_id', attendu_id || '')

    //call api upload files
    fileApi
      .uploadMultiFiles(formData)
      .then((res) => {
        setLoadingUploadFile(false)
        const newData = [...initData, ...res.data]
        onChangeData(newData)
      })
      .catch((e) => {
        setLoadingUploadFile(false)
        alert(e)
      })
  }

  const printFile = (pathUrl: string) => {
    if (pathUrl) {
      let path = `${baseURL}/files/attendu/show/${pathUrl}`
      //open new window
      let mywindow = window.open(path, '_blank')
      if (mywindow) {
        //display file in new window
        mywindow.document.write(
          '<html><head><title>' + document.title + '</title>'
        )
        mywindow.document.write('</head><body >')
        if (
          path.toLowerCase().includes('jpg') ||
          path.toLowerCase().includes('png') ||
          path.toLowerCase().includes('jpeg')
        ) {
          mywindow.document.write(`<img src=${path} alt="qa"/>`)
        }
        if (path.toLowerCase().includes('pdf')) {
          mywindow.document.write(`<embed
                type="application/pdf"
                src=${path}
                
                width="100%"
                height="100%" />`)
        }

        mywindow.document.write('</body></html>')

        mywindow.document.close() // necessary for IE >= 10

        mywindow.onload = function () {
          // wait until all resources loaded
          if (path.toLowerCase().includes('pdf')) {
            setTimeout(function () {
              mywindow?.focus() // necessary for IE >= 10
              mywindow?.print() // change window to mywindow
            }, 3000)
          } else {
            mywindow?.focus() // necessary for IE >= 10
            mywindow?.print() // change window to mywindow
            mywindow?.close() // change window to mywindow
          }
        }
      }
    }
  }

  const removeFileItem = (id: string, path: string) => {
    //delete file through api if file not have id
    if (!id) {
      fileApi
        .deleteFile(path)
        .then((res) => {
          const newData = cloneDeep(initData).filter(
            (item) => item.path !== path
          )
          onChangeData(newData)
        })
        .catch((e) => alert(e))
    } else {
      //if file have id, delete directly
      const newData = cloneDeep(initData).filter((item) => item.path !== path)
      onChangeData(newData)
    }
  }

  return (
    <>
      <Documents
        isOpen={openImport}
        onClose={closeImport}
        onConfirm={onConfirmImport}
      />
      <table className="table-document">
        <thead>
          <tr>
            <th className="form-label">Type</th>
            <th className="form-label">Voir</th>
            <th className="form-label">Télécharger</th>
            <th className="form-label">Imprimer</th>
            <th className="form-label">Actions</th>
          </tr>
        </thead>
        {loadingUploadFile ? (
          <Loading />
        ) : (
          <tbody>
            {convertPhotoType(initData).uploadFile.map((el, index) => (
              <tr key={el.id}>
                <td>{el.name}</td>
                <td>
                  <a
                    className="view"
                    href={`${baseURL}/files/attendu/show/${el.path}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    a
                  </a>
                </td>
                <td>
                  <a
                    className="export"
                    download
                    href={`${baseURL}/files/attendu/${el.path}`}
                  >
                    a
                  </a>
                </td>
                <td>
                  <i className="printer" onClick={() => printFile(el.path)}></i>
                </td>
                <td>
                  <Popconfirm
                    title=""
                    description="Êtes-vous sûr de vouloir supprimer cet élément?"
                    onConfirm={() => removeFileItem(el.id || '', el.path)}
                    okText="Supprimer"
                    cancelText="Annuler"
                    icon={null}
                  >
                    <i className="delete"></i>
                  </Popconfirm>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </>
  )
}

export default DisplayDocuments
