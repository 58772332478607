import '../attendu/Attendu.scss'
import { Col, Modal, notification, Row, Spin } from 'antd'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Tick from '../../../assets/icons/tick-green.svg'
import CLOSE_ICON from '../../../assets/images/close-success.png'
import ERROR from '../../../assets/images/error.png'
import DisplayDocuments from '../../../components/Common/Documents/DisplayDocuments'
import Define from '../../../constants/define'
import { PhotoViewType } from '../../../enum/photoType'
import attenduApi from '../../../http/attenduApi'
import clientWarehouseApi from '../../../http/clientWarehouseApi'
import mobileAppMissionApi from '../../../http/mobileAppMissionApi'
import { Attendu, FileResponse } from '../../../models'
import { getAllAttendu } from '../../../redux/reducers/attenduSlice'
import { convertPhotoType, getCurrentFiltersText } from '../../../utils'
import { DataTableParam } from '../stockscreen/component/model'
import SectionLayout from '../stockscreen/component/SectionLayout'
import Commentaire from './Commentaire'
import DetailView from './DetailView'
import FournisseurView from './FournisseurView'
import { mergeReservesToAttenduData } from './func'
import InformationsView from './InformationsView'
import Mission from './Mission'
import { FData, InformationViewProps } from './model'
import PhotoView from './PhotoView'
import SuiviView from './SuiviView'
import { t } from 'i18next'
import dayjs from 'dayjs'
import { checkIfClientWarehouseCompanyCorrespond } from '../stockscreen/func'

interface ActionProps {
  onClickPlusBtn?(): void

  isShowIconPlus?: boolean
}

const Action = ({
  isShowIconPlus = true,
  onClickPlusBtn = () => {},
}: ActionProps) => {
  const onClickPlus = (): void => {
    if (onClickPlusBtn) onClickPlusBtn()
  }

  return (
    <div className="flex items-center gap-3">
      {isShowIconPlus && (
        <i className={'icon-add-plus'} onClick={onClickPlus}></i>
      )}
    </div>
  )
}

function convertData(result: Attendu) {
  const informationsResult: InformationViewProps = {
    bl: result.bl,
    number_of_fournisseur: result.number_of_fournisseur,
    client: result.client_code_nom,

    commande: result.commande
      ? dayjs(new Date(result.commande * 1000)).format(t('date-format'))
      : '',
    livraison: result.livraison
      ? dayjs(new Date(result.livraison * 1000)).format(t('date-format'))
      : '',
    priorite: result.priorite || 5,
    no_commande: result.no_commande,
    photos: result.photos || 0,
  }

  const missionResult: MissionRole = {
    stockiste: result.assignee_by || '',
    responsable: result.manager_by || '',
  }

  const fDataResult: FData = {
    code_nom: result.att_fournisseur?.code_nom,
    data1: [
      [
        {
          name: 'address',
          value: `${result.att_fournisseur?.addresse} - ${result.att_fournisseur?.code_postal} - ${result.att_fournisseur?.ville} - ${result.att_fournisseur?.pays} (${result.att_fournisseur?.iso})`,
        },
        {
          name: 'complement',
          value: result.att_fournisseur?.complement,
        },
        {
          name: 'gln',
          value: result.att_fournisseur?.gln,
        },
      ],
    ],
    data2: [
      [
        {
          name: 'contact',
          value: result.att_fournisseur?.contact,
        },
        {
          name: 'tel',
          value: result.att_fournisseur?.tel,
        },
        {
          name: 'mobile',
          value: result.att_fournisseur?.mobile,
        },
        {
          name: 'email',
          value: result.att_fournisseur?.email,
        },
      ],
    ],
  }

  const detailDataResult: DataTableParam[][] = []
  for (let i = 0; i < result?.att_production?.length; i++) {
    const currentLine = result?.att_production[i]
    const newLine: DataTableParam[] = []
    newLine.push({ name: 'reference', value: currentLine.reference_number })
    newLine.push({
      name: 'ref_fournisseur',
      value: currentLine.ref_fournisseur_number,
    })
    newLine.push({ name: 'libelle', value: currentLine.libelle })
    newLine.push({ name: 'lot', value: currentLine.lot })
    newLine.push({ name: 'sscc', value: currentLine.sscc_ob?.num })
    newLine.push({
      name: 'dluo',
      value: currentLine.dluo
        ? dayjs(new Date(currentLine.dluo * 1000)).format(t('date-format'))
        : '',
    })
    newLine.push({ name: 'qte', value: currentLine.quantite?.toString() })
    newLine.push({ name: 'cdn', value: currentLine.conditionement })
    newLine.push({ name: 'sous_qte', value: currentLine.sous_qte?.toString() })
    newLine.push({
      name: 'total_pieces',
      value: currentLine.total_pieses?.toString(),
    })
    newLine.push({ name: 'reserves', value: '' })
    newLine.push({ name: 'id', value: currentLine.id })
    detailDataResult.push(newLine)
  }

  const commentaireData = result.att_commentaire?.content

  const fileData = result?.att_file || []

  return {
    informationsResult,
    fDataResult,
    detailDataResult,
    commentaireData,
    fileData,
    missionResult,
  }
}

export interface MissionRole {
  responsable: string
  stockiste: string
}

export default function AttenduOnMission() {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const params = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [openPopupImport, setOpenPopupImport] = useState<boolean>(false)
  const [fileData, setFileData] = useState<FileResponse[]>([])
  const [clientEmail, setClientEmail] = useState<string>('')

  const [fournisseurData, setFournisseurData] = useState<FData>({
    code_nom: '',
    data1: [],
    data2: [],
  })
  const [detailData, setDetailData] = useState<DataTableParam[][]>([])
  const [informationsData, setInformationsData] =
    useState<InformationViewProps>()
  const [commentaireData, setCommentaireData] = useState<string>('')
  const [missionData, setMissionData] = useState<MissionRole>()
  const [resultAttendu, setResultAttendu] = useState<Attendu>()
  const pageIndex = searchParams.get('page-index')
  const pageSize = searchParams.get('page-size')
  const count = Number(searchParams.get('count'))

  const dispatch = useDispatch()
  const onCancel = () => {
    dispatch(getAllAttendu({ isRealTime: false }))
    navigate(
      `/gestion-de-stock/attendu?page-index=${pageIndex}&page-size=${pageSize}&count=${
        count + 1
      }${getCurrentFiltersText()}`
    )
  }

  const onChangeDataFile = async (data: FileResponse[]) => {
    setIsLoading(true)

    const resultAttenduClone = cloneDeep(resultAttendu)
    try {
      if (resultAttenduClone) {
        resultAttenduClone.att_file = data
        resultAttenduClone.modification_by =
          localStorage.getItem(Define.USERNAME) || ''
        resultAttenduClone.modificationer_id =
          localStorage.getItem(Define.USER_ID) || ''
        await attenduApi.editAttendu(resultAttenduClone)
      }
    } catch (error) {}

    try {
      if (!params?.id) return
      const result = (await attenduApi.getAttenduById(params?.id)).data.entry
      setResultAttendu(result)
    } catch (error) {}
    setIsLoading(false)
  }

  useEffect(() => {
    const id = params?.id
    if (!id) return

    async function getAttenduById(id: string) {
      setIsLoading(true)
      try {
        const result = (await attenduApi.getAttenduById(id)).data.entry
        // if 3 warehouse information are different from 3 warehouse chosen from search bar then navigate to dashboard
        if (!checkIfClientWarehouseCompanyCorrespond(result)) {
          return navigate('/tableau-de-bord')
        }
        setResultAttendu(result)
      } catch (e) {
        notification.open({
          className: 'noti noti-error',
          message: (
            <div className="flex items-center">
              <img src={ERROR} alt="error" width={50} className="mr-2.5" />
              Une erreur s'est produite. Merci de réessayer ultérieurement
            </div>
          ),
          placement: 'topRight',
          closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
          duration: 3,
        })
      } finally {
        setIsLoading(false)
      }
    }

    getAttenduById(id)
  }, [params?.id])

  useEffect(() => {
    if (!resultAttendu) return
    setIsLoading(true)
    const {
      informationsResult,
      fDataResult,
      detailDataResult,
      commentaireData,
      fileData,
      missionResult,
    } = convertData(resultAttendu)
    setInformationsData(informationsResult)
    setFournisseurData(fDataResult)
    setDetailData(detailDataResult)
    setCommentaireData(commentaireData || '')
    setFileData(fileData)
    setMissionData(missionResult)

    mobileAppMissionApi
      .getReserve(resultAttendu.id || '')
      .then((res) => {
        const reserveData = res.data.entry
        setDetailData(mergeReservesToAttenduData(detailDataResult, reserveData))
        setIsLoading(false)
      })
      .catch((e) => setIsLoading(false))
  }, [resultAttendu])

  useEffect(() => {
    resultAttendu?.client_code_nom &&
      clientWarehouseApi
        .getClientByCodeNom(resultAttendu.client_code_nom)
        .then((data) => {
          setClientEmail(data?.data?.entry?.email || '')
        })
  }, [resultAttendu?.client_code_nom])

  return (
    <Modal
      prefixCls="modal-new-stock"
      centered
      open={true}
      onCancel={onCancel}
      width={'90%'}
      className="border-solid border-secondary rounded-xl"
      footer={null}
      destroyOnClose={true}
    >
      <Spin spinning={isLoading} style={{ overflow: 'auto' }}>
        <Row className="bd-bot-page-title pb-3.5" style={{ height: '6%' }}>
          <Col className="flex items-center">
            <div className="flex items-baseline">
              <span className="modal-title-heading-1 mr-2">Attendu</span>
              <span className=" modal-title-heading-2 normal-case">
                (n° {informationsData?.bl})
              </span>
            </div>

            <span className="gap-hor-36"></span>
            <span className="flex items-center gap-1">
              <img src={Tick} alt="" />
              <span className="tick-message">Envoyé en mission</span>
            </span>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>
            <span className="text-gray-popup italic">
              Création :{' '}
              {moment(Number(resultAttendu?.create_at) * 1000).format(
                t('time-format')
              )}
            </span>
          </Col>
          <Col>
            <span className="text-gray-popup italic">
              Modification :{' '}
              {moment(Number(resultAttendu?.update_at) * 1000).format(
                t('time-format')
              )}
            </span>
          </Col>
        </Row>
        <div className="gap-9"></div>

        <div className="overflow-y-auto main-content-mission ">
          <SectionLayout title="Informations">
            <InformationsView data={informationsData} onChangeData={() => {}} />
          </SectionLayout>
          <SectionLayout title="Fournisseur">
            <FournisseurView
              code_nom={fournisseurData.code_nom}
              data1={fournisseurData.data1}
              data2={fournisseurData.data2}
            />
          </SectionLayout>
          <SectionLayout title="Détail">
            <DetailView data={detailData} />
          </SectionLayout>
          <SectionLayout title="Commentaire">
            <Commentaire data={commentaireData} />
          </SectionLayout>

          <SectionLayout title="Mission">
            <Mission data={missionData} />
          </SectionLayout>

          <SectionLayout title="Suivi">
            <SuiviView
              resultAttendu={resultAttendu}
              objectEmail={clientEmail}
              errorImages={convertPhotoType(fileData).errFile}
              blNo={informationsData?.bl || ''}
            />
          </SectionLayout>

          <SectionLayout title="Photos">
            <PhotoView
              data={convertPhotoType(fileData).infoFile}
              type={PhotoViewType.MISSON}
            />
          </SectionLayout>
          <SectionLayout
            title="Documents"
            action={<Action onClickPlusBtn={() => setOpenPopupImport(true)} />}
          >
            <DisplayDocuments
              initData={fileData}
              attendu_id={params.id}
              openImport={openPopupImport}
              closeImport={() => setOpenPopupImport(false)}
              onChangeData={onChangeDataFile}
            />
          </SectionLayout>
        </div>
      </Spin>
    </Modal>
  )
}
