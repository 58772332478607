import { EditorContent } from '@tiptap/react'

export const CustomTextArea = ({ editor }: { editor: any }) => {
  return (
    <div className="border-2 border-gray3 border-solid w-[500px] rounded-md px-2">
      {/*<MenuBar editor={editor} />*/}
      <EditorContent editor={editor} autoFocus />
    </div>
  )
}
