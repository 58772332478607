import { Col, Row } from 'antd'
import React from 'react'
import { CInput, NameAndValue } from '../stockscreen/component/Wrapper'
import { InputType } from '../../../enum/InputType'
import { InformationViewProps } from './model'
import { OptionItem } from '../../../components/Common/SelectList/SelectList'

interface Data {
  data?: InformationViewProps
  onChangeData: (e: NameAndValue) => void
}

const prioriteOptions: OptionItem[] = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
]

export default function InformationsView(props: Data) {
  const {
    bl,
    number_of_fournisseur,
    client,
    commande,
    livraison,
    priorite,
    no_commande,
  } = props?.data || {}
  return (
    <div className="attendu-on-mission-info">
      <Row className="gap-0">
        <Col span={6}>
          <Row align="middle">
            <Col span={10}>
              <span className="form-label">N° de BL</span>
            </Col>
            <Col span={10}>
              <CInput value={bl} className="custom-input" disabled />
            </Col>
          </Row>
        </Col>
        <Col span={7}>
          <Row align="middle">
            <Col span={8}>
              <span className="form-label">Commande</span>
            </Col>
            <Col span={13}>
              <CInput
                width={'100%'}
                value={commande}
                type={[InputType.DATE]}
                placeHolder="JJ/MM/AAAA"
                disabled
              />
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row align="middle">
            <Col span={6}>
              <span className="form-label">Livraison</span>
            </Col>
            <Col span={15}>
              <CInput
                value={livraison}
                width={'100%'}
                type={[InputType.DATE]}
                placeHolder="JJ/MM/AAAA"
                disabled
              />
            </Col>
          </Row>
        </Col>
        <Col span={5}>
          <Row align="middle">
            <Col span={6}>
              <span className="form-label">Priorité</span>
            </Col>
            <Col span={12}>
              <CInput
                name="priorite"
                onChange={props?.onChangeData}
                width={100}
                options={prioriteOptions}
                value={priorite}
                className="custom-input"
                type={[InputType.SELECT]}
                disabled
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="gap-9"></div>
      <Row className="gap-0">
        <Col span={6}>
          <Row align="middle">
            <Col span={10}>
              <span className="form-label">N° Fournisseur</span>
            </Col>
            <Col span={10}>
              <CInput
                value={number_of_fournisseur}
                className="custom-input"
                disabled
              />
            </Col>
          </Row>
        </Col>
        <Col span={7}>
          <Row align="middle">
            <Col span={8}>
              <span className="form-label">N° Commande</span>
            </Col>
            <Col span={12}>
              <CInput
                value={no_commande}
                className="custom-input w-100p"
                disabled
              />
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row align="middle">
            <Col span={6}>
              <span className="form-label">Client</span>
            </Col>
            <Col span={14}>
              <CInput value={client} className="custom-input" disabled />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
