import { Tag, Tooltip } from 'antd'
import { InputType } from '../../../enum/InputType'
import { CCell, CInput, CTr } from '../stockscreen/component/Wrapper'
import {
  ActionParam,
  DataTableParam,
  TitleParam,
} from '../stockscreen/component/model'
import './Attendu.scss'
import DangerousIcon from '../../../assets/icons/dangerous.svg'
import { Reserve } from '../../../models/Reserve'

interface LineProps {
  data: DataTableParam[]
  onPrint?: (data: { lineId: string; barcodeURL?: string }) => void
  hasAction?: boolean
}

export function Line(props: LineProps) {
  const onPrint = () => {
    const id = props.data.find((item) => item.name === 'id')?.value
    const gen_bar_code_pdf = props.data.find(
      (item) => item.name === 'gen_bar_code_pdf'
    )?.value
    if (id && props?.onPrint)
      props.onPrint({ lineId: id, barcodeURL: gen_bar_code_pdf })
  }

  return (
    <CTr className="line-in-table">
      {props.data
        .filter((item) => !item.isHide)
        .map((item, idx) => {
          if (item.type?.includes(InputType.TOOLTIP)) {
            const dataInTooltip = item.dataInTooltip as Reserve[]
            const tooltipContent = (dataInTooltip || []).map((item) => {
              const result = (
                <p>
                  {item.reserve_type_name || 'Motif'}: {item.quantity}
                </p>
              )
              return result
            })
            const isExistAtLeastQte = dataInTooltip?.some(
              (item) => item.quantity > 0
            )
            if (!isExistAtLeastQte)
              return (
                <CCell key={idx} className=" bd-right-cell-table text-center">
                  <div className="flex items-center text-center justify-items-center w-full">
                    -
                  </div>
                </CCell>
              )
            return (
              <CCell key={idx} className=" bd-right-cell-table text-center">
                <div className="flex items-center text-center">
                  <Tooltip title={tooltipContent}>
                    <Tag
                      color="transparent"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      <img width={20} src={DangerousIcon} alt="" />
                    </Tag>
                  </Tooltip>
                </div>
              </CCell>
            )
          }

          return (
            <CCell key={idx} className="py-2 bd-right-cell-table text-center">
              <div className="flex items-center text-center justify-center">
                <CInput
                  type={item.type}
                  placeHolder={'-'}
                  disabled
                  value={item.value}
                  className="custom-input w-11/12 text-center"
                  width={item.type?.includes(InputType.DATE) ? 130 : undefined}
                />
              </div>
            </CCell>
          )
        })
        .concat(
          props?.hasAction ? (
            <CCell key={-1} className=" bd-right-cell-table text-center">
              <div className="flex items-center text-center">
                <i onClick={onPrint} className="printer"></i>
              </div>
            </CCell>
          ) : (
            <></>
          )
        )}
    </CTr>
  )
}

export function mapDataWithHeader(
  header: TitleParam[],
  data: DataTableParam[][]
) {
  const result: DataTableParam[][] = []
  for (let i = 0; i < data.length; i++) {
    const currentLine = data[i]
    const currentData = []
    for (let j = 0; j < header.length; j++) {
      const currentHeader = header[j]
      const feature = currentLine.find(
        (item) => item.name === currentHeader.name
      )
      if (feature) {
        feature.type = currentHeader.cInputProps.type
        feature.isHide = currentHeader.isHide
        currentData.push(feature || { name: '', value: '' })
      }
    }
    result[i] = currentData
  }
  return result
}

interface HeaderProps {
  header: TitleParam[]
  hideCols: string[]
  actions?: ActionParam[]
  hasAction?: boolean
}

export const Header = (props: HeaderProps) => {
  return (
    <CTr isHeader={true} className="sticky top-0 z-10">
      {props.header.map((item, idx) => {
        if (props.hideCols.includes(item?.name || ''))
          return <div key={idx} className="hidden"></div>
        if (item?.required) {
          return (
            <CCell
              key={idx}
              className={`whitespace-nowrap bd-bot-header-table bd-right-header-table  text-center ${item.cInputProps.className}`}
            >
              <span className="text-header-table txt-required">
                {item.label}
              </span>
            </CCell>
          )
        }
        return (
          <CCell
            key={idx}
            className={`whitespace-nowrap bd-bot-header-table bd-right-header-table  text-center  ${item.cInputProps.className}`}
          >
            <span className="text-header-table">{item.label}</span>
          </CCell>
        )
      })}

      {props?.hasAction && (
        <CCell
          key={-1}
          className="whitespace-nowrap bd-bot-header-table text-center py-3"
        >
          <span className="text-header-table">Actions</span>
        </CCell>
      )}
    </CTr>
  )
}
