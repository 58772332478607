import { useState } from 'react'
import { InputType } from '../../../enum/InputType'
import attenduApi from '../../../http/attenduApi'
import { CTable } from '../stockscreen/component/Wrapper'
import { TitleParam } from '../stockscreen/component/model'
import { Header, Line, mapDataWithHeader } from './Common'
import { DetailData } from './model'
import Loading from '../../../components/Common/Loading/Loading'

const header: TitleParam[] = [
  {
    name: 'id',
    label: 'id',
    isHide: true,
    cInputProps: {
      type: [InputType.ALL_TEXT],
    },
  },
  {
    name: 'reference',
    label: 'Référence',
    cInputProps: {},
  },
  {
    name: 'ref_fournisseur',
    label: 'Réf fournisseur',
    cInputProps: {},
  },
  {
    name: 'libelle',
    label: 'Libellé',
    cInputProps: {},
  },
  {
    name: 'lot',
    label: 'Lot',
    cInputProps: {},
  },
  {
    name: 'sscc',
    label: 'SSCC',
    cInputProps: {},
  },
  {
    name: 'dluo',
    label: 'DLUO',
    cInputProps: {
      type: [InputType.DATE],
      width: 130,
    },
  },

  {
    name: 'cdn',
    label: 'Cdn',
    cInputProps: {},
  },
  {
    name: 'qte',
    label: 'Qte',
    cInputProps: {},
  },

  {
    name: 'sous_qte',
    label: 'Sous-Qte',
    cInputProps: {},
  },
  {
    name: 'total_pieces',
    label: 'Total pièces',
    cInputProps: {},
  },
  {
    name: 'reserves',
    label: 'Réserves',
    cInputProps: { type: [InputType.TOOLTIP] },
  },
]

export default function DetailView(props: DetailData) {
  const baseURL = process.env.REACT_APP_API_ENDPOINT
  const [loading, setLoading] = useState<boolean>(false)

  const onPrint = async (data: { lineId: string; barcodeURL?: string }) => {
    const barcodeURL = data.barcodeURL
    setLoading(true)

    let path = `${baseURL}/files/common/show/attendu/`
    if (barcodeURL) {
      path = `${path}${barcodeURL}`

      setLoading(false)
    } else {
      try {
        const response = await attenduApi.printInforAtt(data.lineId)
        path = `${path}${response.data.path}`
        setLoading(false)
      } catch (e) {
        console.log(e)
      }
    }
    let mywindow = window.open(path, '_blank')
    if (mywindow) {
      //display file in new window
      mywindow.document.write(
        '<html><head><title>' + document.title + '</title>'
      )
      mywindow.document.write('</head><body >')
      if (
        path.toLowerCase().includes('jpg') ||
        path.toLowerCase().includes('png') ||
        path.toLowerCase().includes('jpeg')
      ) {
        mywindow.document.write(`<img src=${path} alt="qa"/>`)
      }
      if (path.toLowerCase().includes('pdf')) {
        mywindow.document.write(`<embed
                type="application/pdf"
                src=${path}
                
                width="100%"
                height="100%" />`)
      }

      mywindow.document.write('</body></html>')

      mywindow.document.close() // necessary for IE >= 10

      mywindow.onload = function () {
        // wait until all resources loaded
        if (path.toLowerCase().includes('pdf')) {
          setTimeout(function () {
            mywindow?.focus() // necessary for IE >= 10
            mywindow?.print() // change window to mywindow
          }, 3000)
        } else {
          mywindow?.focus() // necessary for IE >= 10
          mywindow?.print() // change window to mywindow
          mywindow?.close() // change window to mywindow
        }
      }
    }
  }

  return (
    <div className="attendu-on-mission-info">
      {loading && <Loading />}
      <CTable>
        <Header header={header} hideCols={['id']} hasAction />
        {mapDataWithHeader(header, props.data).map((item, idx) => {
          return (
            <Line key={idx} data={item} onPrint={onPrint} hasAction={true} />
          )
        })}
      </CTable>
    </div>
  )
}
